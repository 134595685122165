import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import styled from 'styled-components';
import 'normalize.css';

import Nav from './Nav';
import Footer from './Footer';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import 'animate.css';
import { animateCSS } from '../utils/animateHelper';
import { IconContext } from "react-icons";
//import bg from '../assets/images/BG/fondo.png'


const SiteBorderStyles = styled.div`
    position:relative;
    overflow-x: hidden;
    min-height: 100vh;

    .parallax-container {
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      filter: brightness(0.6);
        .img-wrapper, .img-wrapper-base {
            position: absolute;
            top: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
        }

        .img-wrapper-base {
          z-index: 0;
          .gatsby-image-wrapper {
            height: 100vh;
          }
        }

    }

    .floating {  
      animation-name: floating;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      width: calc(100% - 15px) !important;
      height: calc(100% - 20px) !important;
    }
      
    @keyframes floating {
        0% { transform: translate(5px,  0px); } 
        50%  { transform: translate(5px, 15px); }
        100%   { transform: translate(5px, -0px); }    
    }

  @media (max-width: 1440px) {
   /* background-image: url(${bg});
    /*background-position: 0 -50px;*/
   /* margin-left: 1rem;
    margin-right: 1rem;*/
    max-width: 100%;
  }

  @media (max-width: 1100px) {
   /* background-image: url(${bgMobile});
    background-position: 0 0;*/
    max-width: 100%;
  }
`;

const ContentStyles = styled.div`
  position: relative;
  z-index: 3;
`;

export default function Layout({ children }) {
  const ref = useRef();
  const { site } = useStaticQuery(graphql`
  query {
      site {
        siteMetadata {
          links {
            link
            name
          }
        }
      }
    }
`);

useEffect(() => {
  if(typeof window != "undefined") {
    setTimeout( () => animateCSS('.lineas', 'pulse'), 1000);
  }

}, []);

const { links } = site.siteMetadata;
  return (
    <>
      <GlobalStyles />
      <Typography />
      <IconContext.Provider value={{ style: { verticalAlign: 'middle', size:"2em"} }}>
        <SiteBorderStyles>
          <div>
          {children.key == "/" &&
            <div className="parallax-container">
              <div className="img-wrapper-base">
                <StaticImage src="../assets/images/BG/bg.jpg"
                      className=""
                      layout="fullWidth" 
                      alt="bg"
                      style={{ filter: "brightness(0.65)",
                      gridArea: "1/1",
                      // You can set a maximum height for the image, if you wish.
                     
                      height: "100%",
                      width: "100vw",
                      position: "fixed",
                      top: 0,
                      left: 0,
                      zIndex: 0,}}
                      />
                </div>    
              </div>
            }
          </div>
          <ContentStyles>  
            {/*<Nav active={children.key} logoTrack={ref} links={links}/> */} 
            {children}      
          </ContentStyles>
          {/*<Footer active={children.key} links={links}/>*/}
        </SiteBorderStyles>
      
      </IconContext.Provider>
    </>
  );
}
