import React, { useState, useEffect, useCallback, useRef  } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { HiMenu } from 'react-icons/hi';

import useOnScreen from '../utils/useOnScreen';

import Logo from './Logo';
import Menu from './Menu';
import SocialLinks from './SocialLinks';
import { StaticImage } from 'gatsby-plugin-image';

const NavStyles = styled.nav`
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  &:hover {
    z-index: 1000;
  }


  ul {
    margin: 5% 0 0 0;
    padding: 0 0.5rem 2rem 0.5rem;
    text-align: center;
    list-style: none;
    display: grid;
    
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    min-height: 8rem;

    &.sticked {
      grid-template-columns: repeat(4, 1fr);
      padding: 2rem 15%;
    
        li {
          padding: 1.5rem;
          a {
              color: var(--black);
            }
          span {
            color: var(--black);
          }
          &.active {
            a {
              color: var(--light-blue);
              text-decoration: underline;
            }
            span {
              color: var(--light-blue);
              text-decoration: none;
            }
          }
        }

      svg {
          display: none;
        }

      .social {
        height: 6rem;
          a {
            display: flex;
            justify-content: center;
            align-content: center;
            color: var(--white);
            vertical-align: center;
            text-align: center;
            padding: 5px 5px 3px 5px;
            svg {
              display: block;
            }
          }
        }
    }
    svg {
          display: none;
        }
  }

  li {
    height: 5rem;
    padding: 0.5rem;
    order: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &.logo-holder {
      grid-column: 1 / span 3;
      grid-row: 1;
      display: block; 
      order: 1;
    }

    &.link {
      order: 2;
    }

    &.active {
      a {
        text-decoration: underline;
      }
      span {
        text-decoration: none;
      }
    }
  }
  a {
    font-size: 2rem;
    text-decoration: none;
    color: var(--white);
    display: block;
    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--red);
    }

    @media (max-width: 800px) {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 600px) {
    --columns: 5;
    margin-bottom: 2rem;
    padding-bottom: 2rem;

    ul {
      display: none;
      justify-items: center;
      min-height: 6rem;

      .burgermenu {
        grid-column: 1 ;
        grid-row: 1;
      }

      &.sticked {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(var(--columns), 1fr);
        margin: 0;
        padding: 0;
        padding-top: 1rem;
        svg {
          display: block;
        }

        li {
          display: none;
            &.logo-holder {
            grid-column: 2 / span 2;
            grid-row: 1 / span 2;
            display: block;
          }
        }
      }

      li {
        a {
          font-size: 1.2rem;
        }
        &.sticked, .sticked {
          display: none;
        }

        &.logo-holder {
          grid-column: 1;
          grid-row: 1 / span 2;
        }
      }
    }
    .logo-item {
      order: 0;
      grid-column: 1 / -1;
    }
    .logo {
      transform: none;
    }
  }
  @media (max-width: 500px) {
    --columns: 5;
    ul {
      &.sticked {
        grid-template-columns: repeat(var(--columns), 1fr);
        margin: 0;
        padding: 0;
        padding-top: 1rem;
        
      }
    }
  }
`;

const  WIDTH = 120;
const maxWidth = '140px';
const PLANT = <StaticImage src="../assets/images/PLANT.png"
  className=""
  placeholder="none"
  style={{maxWidth }}
  width={WIDTH}              
  alt="logo" />   
const WEED = <StaticImage src="../assets/images/WEED.png"
  className=""
  placeholder="none"
  style={{maxWidth }}
  width={WIDTH}              
  alt="logo" />   
const FUNG = <StaticImage src="../assets/images/FUNG.png"
  className=""
  placeholder="none"
  style={{maxWidth }}
  width={WIDTH}              
  alt="logo" />   
const NOSOTROS = <StaticImage src="../assets/images/NOSOTROS.png"
  className=""
  placeholder="none"
  style={{maxWidth }}
  width={WIDTH}              
  alt="logo" />   

export default function Nav({
  active,
  logoTrack,
  className = '',
  customStyle,
  links
}) {

  const isVisible = useOnScreen(logoTrack);
  const [menu, isMenu] = useState(false);

  const [stacked, isStacked] = useState(false);
  const [offset, setOffset] = useState(0);

  const icons = useRef(
    {
      PLANT,
      WEED,
      FUNG,
      NOSOTROS
    }
  )

  useEffect(() => {
    try {
      isStacked(!isVisible && typeof window !== 'undefined' && window.pageYOffset > 675)
    } catch (e) {
      console.log(e);
    }
  }, [isVisible, offset]);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    }
  }, []);

  const handleClick = useCallback(() => {
    // handle the click event
      isMenu(!menu)
  }, []);


  return (
    <NavStyles style={customStyle} className={className}>
      <ul className="sticked">
        {!isVisible && <HiMenu className="burgermenu" onClick={handleClick} />}

        {links.map ( s => 
          <li key={s.name} className={active === s.link ? 'active link' : 'link'}>
            <Link to={s.link}>{icons?.current[s.name]}</Link>
          </li>)
        }

      </ul>
      
      
      {!isVisible && <Menu active={menu} showModal={isMenu}>
            <div className="mobileMenuContainer">
              <div className={ ''}>
                <Link to="/" >
                  Home
                </Link>
              </div>
              {links.map ( s => 
                <div key={s.name} className={active === s.link ? 'active' : ''}>
                  <Link to={s.link}>                  
                    {s.link}
                  </Link>
                </div>)
              }
            </div>
          </Menu>
      }
    </NavStyles>
  );
}
