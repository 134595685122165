import React from 'react';
//import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'
//import SocialLinks from './SocialLinks';
import { BiMailSend, BiPhoneCall } from 'react-icons/bi';
import { FaWhatsapp } from 'react-icons/fa';
import { MdPinDrop } from 'react-icons/md';

const FooterStyles = styled.footer`
position: relative;
min-height: 20rem;
display: grid;
grid-template-columns: 1fr 3fr;
margin: 0;
grid-area: 1/1;

 
justify-content: center;
align-items: center;
z-index: 3;
  .cont {
    margin: 3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    p {
      margin-block-start: 2px;
      margin-block-end: 2px;
    }
  }

  .cont-social {
    display: grid;
    grid-gap: 1.5rem;
    padding: 0 10%;
    align-items: center;

    font-family: font, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 3rem;
    color: var(--red);
     a {
       text-decoration: none;

       &:hover {
        text-decoration: underline;
       }
     }

  }


@media (max-width: 1100px) {
  grid-template-columns: 1fr;
  height: 120vh;

  .cont {
    margin: 10rem 6rem 0 6rem;
  }


}
`

const FooterHeadStyles = styled.div`
  position: relative;
  min-height: 20rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 40px;
  margin: 0;
  grid-area: 1/1;

  justify-content: center;
  justify-items: center;
  align-items: center;
  z-index: 3;
  text-align: center;

  padding: 5% 0 0 0;

  h1 {
    color: #fff;
    font-size: 6rem;
  }


  @media (max-width: 1100px) {
    margin-bottom: 2rem;
    min-height: 4rem;
    height: 6rem;
    h1 {
      color: #fff;
      font-size: 4rem;
    }
  }
`
function Footer({ active, links }) {
  return (
        <div style={{ display: "grid" }} id="contacto">  
          <StaticImage src="../assets/images/BG/bg.jpg"
                  className=""
                  layout="fullWidth"                        
                  alt=""
                  style={{
                    gridArea: "1/1",
                    // You can set a maximum height for the image, if you wish.
                    // maxHeight: 600,
                    filter: 'opacity(0.3)',
                  }}     
                  formats={["auto", "webp", "avif"]} 
                  aspectRatio={16/9}            
                  />       

                <FooterHeadStyles  >
                  <h1>CONTACTO</h1>
 
              </FooterHeadStyles>   
          <FooterStyles>
              <div className="cont">
                <StaticImage src="../assets/images/logo.png"
                        className=""
                        placeholder="none"
                        style={{maxWidth:'480px'}}
                        width={320}              
                        alt="logo" />  
  
              </div>
              <div className="cont-social">
    
                  <a href="mailto:administracion@arqcons.com.co"><BiMailSend /> administracion@arqcons.com.co</a>
                  <a href="tel:3579219"><BiPhoneCall /> 357 9219</a>
                  <a href={`https://api.whatsapp.com/send?phone=+573245454974`}><FaWhatsapp /> +57 324 5454974</a>
                  <a href="https://goo.gl/maps/wUFxXUC7B4AYCEc3A"><MdPinDrop  /> Dirección: Calle 48B N. 81-57</a>   

              </div>
          </FooterStyles>

        </div>

   
  );
}

export default React.memo(Footer);
