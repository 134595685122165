import { createGlobalStyle } from 'styled-components';

 import fontHead from '../assets/fonts/haettenschweiler.ttf';
import font from '../assets/fonts/AndorRegular-PersonalUse.ttf';
import fontAlt from '../assets/fonts/Montserrat-Regular.ttf';
import fontLogo from '../assets/fonts/Play-Bold.ttf';


const Typography = createGlobalStyle`
  @font-face {
    font-family: FrenchFries;
    src: url(${font});
  }

  @font-face {
    font-family: FontAlt;
    src: url(${fontAlt});
  }

  @font-face {
    font-family: fontHead;
    src: url(${font});
  }

  @font-face {
    font-family: Play;
    src: url(${font})
  }

  
  html {
    font-family: fontAlt, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
    color: var(--red);
  }
  p, li {
    letter-spacing: 0.5px;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: fontHead, Play, FrenchFries, 'Courier New', Roboto, Oxygen, Ubuntu, sans-serif;
    font-weight: normal;
    margin: 0;
    &.play {
      font-family: Play, FrenchFries, 'Courier New', Roboto, Oxygen, Ubuntu, sans-serif;
    }
  }
  a {
    color: var(--red);
    text-decoration-color: var(--red);
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
  }
  mark, .mark {
   /* background: var(--light-blue);*/
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
    letter-spacing: 2px;
  }

  .center {
    text-align: center;
  }

  .pd-md {
    padding: 2rem;
  }

  .text-banner {
    font-family: fontHead, Ubuntu, sans-serif !important;
    font-size: 3.5rem;
    background-color: var(--red);
    color: #FFF;
    padding: 1rem;
  }

  .tilt {
    transform: rotate(-2deg);
  }

  p, .courier {
    font-family: fontAlt ,'Courier New', Roboto, Oxygen, Ubuntu, sans-serif;
  }

  p {
    font-size: 1.5rem;
  }

  .palace {
    font-family: FrenchFries, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
  }

  .ft-head {
    font-family: fontHead, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
    font-weight: bolder;
    text-decoration: none !important;
  }

  .head {
    font-family: fontHead, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
    font-size: 6rem;
    font-weight: bolder;
    text-decoration: none !important;
  }

  @media (max-width: 1100px) {
    .head {
      font-size: 3rem;
    }

    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.75rem;
    }
    h3 {
      font-size: 1.17rem;
    }

    body {
      font-size: 1.5rem;
    }

    p, ul, li, span, a {
      font-size: 1.2rem;
    }
  }

`;

export default Typography;
