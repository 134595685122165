import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaWhatsapp} from 'react-icons/fa';

const SocialStyles = styled.div`
  display: grid;
  --columns: 1;
  grid-template-columns: repeat(var(--columns), 1fr);
  justify-content: center;
  align-items: center;
  justify-items: center;
  color: var(--red);
  &.jumbo {
    margin: 0;
    height: 10vh;

    color: var(--white);
      a {
        color: var(--white);
        &:hover {
          color: var(--light-blue);
        }
      }
  }
  
  a {
    display: flex;
    justify-content: center;
    align-content: center;
    justify-items: center;
    margin: 0 1rem;
    text-decoration: none;
    color: var(--white);
    background-color: var(--red);
    border-radius: 60px;
    padding: 5px 5px 3px 5px;
    width: 2.75rem;
    height: 2.75rem; 
    svg {
      width: 2.5rem;
      height: 2.5rem; 
    }
    &:hover {
          color: var(--white);
        }

  }
  
  @media (max-width: 800px) {
    --columns: 1;
    grid-gap: 1rem;
    &.jumbo a, a {
      margin: 0;
      margin-top: 1rem;
      span {
        display: none;
      }

    }
  }
`;

export default function SocialLinks({ showDesc = true, className }) {
  const { site } = useStaticQuery(graphql`
  query {
      site {
        siteMetadata {
          title
          mail
          facebook
          whatsapp
          instagram
        }
      }
    }
`);

const {  mail, facebook, instagram, whatsapp } = site.siteMetadata;
  return (
      <SocialStyles className={className}>        
        <a href={`https://www.instagram.com/${instagram}`} alt={instagram}> <FaInstagram /></a>
      </SocialStyles>
  );
}
